#ido-info-view {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	background-color: transparent;

	.ido-time {
		width: 80%;
		height: 60px;
		max-width: 1380px;
		background: linear-gradient(90deg, #00C6F5 0%, #00FE80 100%);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px;
		font-family: "gilroyExtraBold";
		div:nth-child(1) {
			color: #000000;
			font-size: 20px;
			font-weight: 700;
		}
		div:nth-child(2) {
			color: #000000;
			font-size: 20px
		}
	}

	.ido-total {
		margin-top: 40px;
		box-sizing: border-box;
		padding: 40px;

		width: 80%;
		max-width: 1380px;

		background: rgba(0, 0, 0, 0.6);
		border: 1px solid #00FE80;
		border-radius: 10px;
		display: flex;
		.ido-total-item {
			flex: 1;
			display: flex;
			justify-content: center;
			flex-direction: column;
			font-weight: 700;
			font-family: 'gilroy';
			div:nth-child(1) {
				font-size: 14px;
				line-height: 17px;
				
				/* identical to box height */

				text-align: center;

				color: #EEF2F9;
			}
			div:nth-child(2) {
				font-family: 'Gilroy';
				font-style: normal;
				font-weight: 900;
				font-size: 30px;
				line-height: 37px;
				text-align: center;

				color: #00FE80;

			}
		}
	}
	.ido-card {
		width: 80%;
		max-width: 566px;
		margin-top: 38px;
		background: rgba(0, 0, 0, 0.75);
		border-radius: 32px;
		border-style: solid;
		border-color: #00fe80;
		border-width: 4px;
		padding: 72px 48px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		font-family: "gilroy";
	}

	.ido-card-buy {
		font-size: 36px;
		font-weight: 900;
		font-variation-settings: "wght" 700;
		letter-spacing: 0.05em;
		background: linear-gradient(90deg, #00C6F5 0%, #00FE80 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.ido-card-amount {
		width: 100%;
		height: 60px;
		background: rgba(0, 0, 0, 0.6);
		mix-blend-mode: normal;
		// border: 1px solid #00FE80;
		border-radius: 8px;
		border-color: #00fe80;
		input {
			text-align: right;
			font-family: "gilroy";
			font-size: 24px;
		}
	}

	.ethIcon {
		width: 20px;
		background-color: #FFF;
		border-radius: 100px;
		margin-right: 10px;
	}

	.eth {
		display: flex;
		font-size: 16px;
		font-family: "gilroy";
	}
	.symbol {
		font-weight: 900;
		font-size: 16px;
		font-family: "gilroy";
	}
	.balance {
		margin-top: 10px;
		font-size: 14px;
		line-height: 20px;
		margin-right: 20px;
		/* identical to box height, or 143% */
		font-family: "gilroyLight";
		font-weight: 500;
		text-align: right;
		letter-spacing: -0.4px;

		color: #00FE80;
	}
	.ido-card-receive {
		margin-top: 20px;
		background: rgba(0, 0, 0, 0.6);
		mix-blend-mode: normal;
		border: 1px solid rgba(255, 255, 255, 0.16);
		border-radius: 10px;
		padding: 10px;
		display: flex;
		justify-content: space-between;
		font-family: "gilroy";

		.receive {
			display: flex;
			align-items: center;
			word-break: break-all;
			.name {
				font-size: 18px;
				color: rgba(255, 255, 255, 0.54);
			}

			.token-amount {
				font-size: 24px;
				color: #FFFFFF;
				margin-left: 10px;
			}

			.token-icon {
				width: 30px;
				height: 30px;
				border-radius: 100px;
				margin-left: 10px;
			}

			.token-symbol {
				margin-left: 5px;
				font-size: 16px;
				line-height: 20px;
				/* identical to box height, or 125% */

				letter-spacing: -0.4px;

				/* GNS */

				background: linear-gradient(90deg, #00C6F5 0%, #00FE80 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
			}
		}
		.confirm {
			background: linear-gradient(90deg, #00C6F5 0%, #00FE80 100%);
			border-radius: 8px;
			color: #000;
			font-weight: 900;
			font-size: 18px;
			padding: 20px 35px;
			text-align: center;
			user-select:none; 
			cursor: pointer;
			h6 {
				font-family: 'gilroy';
			}
		}
		.confirm:active {
			background: linear-gradient(90deg, #00FE80 0%, #00C6F5 100%);
			transform: translate(1px, 1px);
		}
		
	}
	.ido-card-bougth {
		margin-top: 20px;
		background: rgba(0, 0, 0, 0.6);
		mix-blend-mode: normal;
		border: 1px solid rgba(255, 255, 255, 0.16);
		border-radius: 10px;
		padding: 10px;

		font-family: "gilroy";
		.ido-card-role {
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			div:nth-child(1) {
				font-size: 18px;
				color: rgba(255, 255, 255, 0.54);
			}
			div:nth-child(2) {
				font-size: 16px;
				color: #FFF;
				span {
					font-weight: 500;
					text-align: right;
					letter-spacing: -0.4px;
					color: rgba(255, 255, 255, 0.54);
				}
			}
		}
	}
	.ido-bougth {
		display: flex;
		justify-content: space-between;
		
	}
	@media only screen and (max-width: 635px) {
		.ido-card-receive {
			flex-direction: column;
		}
		.receive {
			justify-content: flex-end;
		}
		.confirm {
			margin-top: 10px;
		}
		.ido-total {
			flex-direction: column;
			padding: 20px;
		}
		.ido-total-item{
			justify-content: space-between !important;
			align-items: center !important;
			flex-direction: row !important;
			padding: 10px 20px;
			div:nth-child(2) {
				font-size: 22px !important;

			}
		}
		.ido-card {
			padding: 20px;
		}
	}
}